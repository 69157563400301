window.modal.def = {
    'carrier-warning-notes': {
        title: '',
        body: `Per evitare ritardi assicurati di indicare un <b>LUOGO PRESIDIATO</b>, ovvero dove sia presente qualcuno che
                possa ritirare il pacco (per esempio una casa con la portineria, un ufficio, un negozio ecc.).
                Non possiamo garantire il rispetto di fasce orarie troppo limitate o di giorni di apertura discontinuati.`,
        buttons: []
    },
    'pallet-must-select-province': {
        title: 'Selezione Provincia',
        body: "Per procedere con l'acquisto &egrave; necessario selezionare la provincia di consegna, al fine di determinare i costi di consegna.",
    },
    'order-type-pallet': {
        title: 'Servizio Bancali',
        body: function (modalData) {
            let html = `
                <div>I Bancali di Carta hanno un percorso d'ordine indipendente da altri articoli.<br/></div>
                `;
            if (modalData.cartHasPallet)
                html += `
                <div class="mt-2">
                    Per acquistare altri articoli effettuare ordini distinti.<br/>
                    Vai al carrello per completare l'ordine dei bancali o rimuovili per acquistare altri articoli.
                </div>
                `;
            else
                html += `
                <div class="mt-2">
                    Completare l'ordine degli articoli scelti o eliminarli dal carrello per ordinare i bancali.
                </div>
                `;
            return html;
        },
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Procedi', data: true, full: true, href: '/carrello.jsp'},
        ]
    },
    'pallet-shipping-deferred': {
        title: 'Servizio Bancali',
        body: function (modalData) {
            return `
                <div><span class="text-Other-Error">ATTENZIONE</span>:
                Gli ordini dei bancali <b>saranno messi in lavorazione dal giorno 19 di agosto</b>.<br/></div>
                `;
        },
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Accetto e Proseguo', data: true, full: true},
        ]
    },
    'cart-pallet-delivery-method': {
        title: 'Servizio Bancali - Consegna su strada',
        body: 'La consegna dei Bancali di Carta avviene con sponda idraulica su piano strada, senza eccezioni.\n' +
            '<br/>La consegna avviene in 5/7 giorni lavorativi tra le 09:00 e le 17:00.',
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Accetto e Proseguo', data: true, full: true},
        ]
    },
    'cart-pallet-payment-method': {
        title: 'Servizio Bancali - Pagamento',
        body: 'Modalità di pagamento dei Bancali di Carta:<br />Bonifico Bancario Anticipato',
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Accetto e Proseguo', data: true, full: true},
        ]
    },
    // 'cart-pallet-delivery-delay': {
    //     title: 'Servizio Bancali - Giorni di consegna',
    //     body: 'Durante il periodo natalizio, la consegna avverrà in 10 giorni lavorativi',
    //     buttons: [
    //         {label: 'Annulla', data: false, full: false},
    //         {label: 'Accetto e Proseguo', data: true, full: true},
    //     ]
    // },

    'chair-wo-armrests': {
        title: 'Attenzione',
        body: `
            Il prodotto &egrave; venduto <b>senza braccioli</b>.<br />
            Volete aggiungere il prodotto al carrello anche se &egrave; venduto senza braccioli?<br/>
        `,
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },
    'warning-carta-forata': {
        title: 'Attenzione',
        body: `
            La carta che stai acquistando è <b>preforata</b>.<br />
            Vuoi aggiungere il prodotto al carrello?<br/>
        `,
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },
    'warning-carta-a5': {
        title: 'Attenzione',
        body: `
            La carta che stai acquistando <b>NON É carta A4</b>.<br />
            Il formato dell’articolo è <b>A5</b> cioè la <b>metà</b> del formato da fotocopia.<br />
            Vuoi aggiungere il prodotto al carrello?
        `,
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },
    'raccoglitore-confezione-maggiore-uno': {
        title: 'Attenzione',
        body: function (modalData) {
            return `
                Il prodotto è venduto in confezione da ${modalData.packageSize} pezzi.<br />
                Vuoi procedere con l'acquisto di ${modalData.cartUpdateQuantity} confezioni<br />
                <b>per un totale di ${modalData.totalQuantity} pezzi</b>?
                `;
        },
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },
    'pet-delayed-delivery': {
        title: 'Attenzione',
        body: function (modalData) {
            return `
                I prodotti per animali potrebbero essere consegnati separatamente e <b>non rispettare i tempi standard</b> di consegna.
                <br/>Volete aggiungere il prodotto al carrello pur sapendo che la spedizione potrebbe non rispettare i tempi standard?
                `;
        },
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },
    'delivery-within-days': {
        title: 'Tempi di consegna',
        body: function (modalData) {
            return `
                Il prodotto richiesto sar&agrave; consegnato <b>${modalData.deliveryText}</b>
                <br/>Volete aggiungere il prodotto al carrello e riceverlo nei tempi indicati?<br/>
                `;
        },
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Aggiungi comunque', data: true, full: true},
        ]
    },

    'cart-removed-products': {
        title: 'Prodotti non pi&ugrave; disponibili',
        body: function (modalData) {
            let html = `
                Gentile cliente, i prodotti elencati di seguito non sono pi&ugrave; disponibili
                e pertanto sono stati rimossi dal suo carrello.
                <br /><br />
                <ul>`;
            modalData.products.forEach(p => {
                html += `<li style="list-style: disc;"><span class="product-title">${p}</span></li>`;
            });
            html += '</ul>';
            return html;
        },
        buttons: [
            {label: 'Ok', data: true, full: true}
        ]
    },
    'cart-confirm-remove': {
        title: 'Conferma Eliminazione',
        body: 'Sei sicuro di voler eliminare il prodotto dal carrello?',
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Rimuovi', data: true, full: true},
        ]
    },
    'cart-order-minimum-not-satisfied': {
        title: 'Ordine Minimo',
        body: function (modalData) {
            return `E' necessario raggiungere almeno <span class="font-bold">${modalData.orderMinimumWVat.toFixed(2)}€</span> di articoli (IVA inclusa) per poter procedere.`
        },
        buttons: []
    },
    'cart-request-delivery-to-floor-selection': {
        title: 'Consegna al piano',
        body: 'Si prega di selezionare se si desidera o meno ricevere la consegna al piano. Il servizio &egrave; a pagamento.',
        buttons: []
    },
    'cart-add-qta-max': {
        title: 'Prodotto a disponibilit&agrave; limitata',
        body: function (modalData) {
            return 'Possibilit&agrave; di acquisto massimo ' + modalData.actualQuantity + ' pezzi.';
        }
    },
    'cart-add-low-stock': {
        title: 'Prodotto a disponibilit&agrave; limitata',
        body: function (modalData) {
            return `
                Il prodotto &egrave; in disponibilit&agrave; limitata.
                <br/>Se vuole ricevere suggerimenti su prodotti alternativi ci pu&ograve; contattare
                <br>a <a rel="nofollow" class="link" href="mailto:info@ufficiodiscount.it">info@ufficiodiscount.it</a>
                <br>oppure allo 02.89.12.43.61
                `;
        }
    },
    'cart-login-before-save-cart': {
        title: 'Salva Carrello',
        body: 'Per salvare il carrello &egrave; necessario effettuare l\'accesso.\n' +
            '<br/><a class="link" href="/registrati_entra.jsp">Accedi</a>',
        buttons: []
    },

    'saved-cart-replace-or-add-products': {
        title: 'Conferma ripristino carrello salvato',
        body: 'I prodotti presenti nel carrello salvato saranno aggiunti al carrello per gli acquisti.\n' +
            '<br/>Desiderate aggiungerli oppure sostituirli a quelli gi&agrave; presenti?',
        buttons: [
            {label: 'Aggiungi', data: 'add', full: true},
            {label: 'Sostituisci', data: 'set', full: true},
        ]
    },
    'saved-cart-confirm-delete': {
        title: 'Conferma Eliminazione',
        body: 'Sei sicuro di voler eliminare questo carrello salvato?',
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Elimina', data: true, full: true},
        ]
    },

    'order-replace-or-add-products': {
        title: 'Compra di nuovo',
        body: 'I prodotti presenti in questo ordine saranno aggiunti al carrello.\n' +
            '<br/>Desiderate aggiungerli oppure sostituirli a quelli gi&agrave; presenti?',
        buttons: [
            {label: 'Aggiungi', data: 'add', full: true},
            {label: 'Sostituisci', data: 'set', full: true},
        ]
    },
    'order-confirm-abort': {
        title: 'Annulla Ordine',
        body: 'Annullando l\'ordine sar&agrave; ancora possibile caricarlo nel carrello per un nuovo acquisto.<br/>Procedere con l\'annullamento dell\'ordine?',
        buttons: [
            {label: 'Non Procedere', data: false, full: false},
            {label: 'Procedi', data: true, full: true},
        ]
    },
    'delivery-to-floor-locked-covid': {
        title: 'Consegna al piano',
        body: 'Causa COVID, il corriere ha sospeso il servizio di consegna al piano.'
    },

    'login-alert-cart-merge': {
        title: 'Attenzione - Carrello modificato',
        body: 'Sono stati aggiunti al carrello i prodotti del carrello cliente'
    },
    'login-auth-wrong': {
        title: 'Errore',
        body: 'Codice Cliente o Password errata.'
    },
    'login-social-wrong': {
        title: 'Errore',
        body: 'Errore nel login via social.'
    },
    'shipping-delete-address': {
        title: 'Elimina indirizzo',
        body: function (modalData) {
            return `Vuoi eliminare l'indirizzo?`;
        },
        buttons: [
            {label: 'S&igrave;, elimina', data: true, full: true},
            {label: 'Annulla', data: false, full: false}
        ]
    },
    'coupon-conditions-PRIMO_ORDINE_21': {
        title: 'Condizioni di validità Promo Primo Ordine',
        body: `
            Per godere della <b>Promo Primo Ordine</b> devono essere soddisfatti tutti i requisiti
            <ul class="list-disc px-6 mt-6">
                <li>L'utenza deve essere registrata come Azienda/Associazione</li>
                <li>La promo è valida solo per il primo ordine</li>
                <li>La promo scade dopo 6 mesi dalla data di creazione del cliente</li>
                <li>La promo è valida per ordini il cui imponibile prodotti raggiunge la soglia di 70&euro;</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-CAFFE_2': {
        title: 'Condizioni di validità Promo Caffè Secondo Ordine',
        body: `
            Per godere della <b>Promo Caffè Secondo Ordine</b> devono essere soddisfatti tutti i requisiti
            <ul class="list-disc px-6 mt-6">
                <li>L'utenza deve essere registrata come Azienda/P.IVA</li>
                <li>La promo è valida solo per il secondo ordine</li>
                <li>La promo scade dopo 6 mesi dalla data di lavorazione del primo ordine</li>
                <li>La promo è valida per ordini il cui imponibile prodotti raggiunge la soglia di 30&euro;</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-CAFFE_5': {
        title: 'Condizioni di validità Promo Caffè 5€',
        body: `
            Per godere della <b>Promo Caffè 5€</b> devono essere soddisfatti tutti i requisiti
            <ul class="list-disc px-6 mt-6">
                <li>La promo scade dopo 6 mesi dalla data assegnazione del coupon</li>
                <li>La promo si applica all'imponibile dei prodotti di tipo <a href="/cibo-bevande-e-catering/caffe-zucchero-e-accessori/">caffè</a>, fino ad un massimo di 5€</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-SECONDO6': {
        title: 'Condizioni di validità Promo Secondo Ordine',
        body: `
            Condizioni del coupon <b>Promo Secondo Ordine</b>
            <ul class="list-disc px-6 mt-6">
                <li>Ordine minimo 70&euro; imponibile prodotti</li>
                <li>Validità della promo 6 mesi e solo sul prossimo ordine</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-20EURO_STD': {
        title: 'Condizioni di validità Promo',
        body: `
            Condizioni del coupon
            <ul class="list-disc px-6 mt-6">
                <li>Ordine minimo 150&euro; imponibile prodotti</li>
                <li>Validità della promo 1 mese e solo sul prossimo ordine</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-20ANNI_20': {
        title: 'Condizioni di validità Promo',
        body: `
            Condizioni del coupon
            <ul class="list-disc px-6 mt-6">
                <li>Ordine minimo 100&euro; imponibile prodotti</li>
                <li>Validità della promo 1 mesi e solo sul prossimo ordine</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-20ANNI_50': {
        title: 'Condizioni di validità Promo 20 Anni',
        body: `
            Condizioni del coupon <b>Promo 20 Anni</b>
            <ul class="list-disc px-6 mt-6">
                <li>Ordine minimo 150&euro; imponibile prodotti</li>
                <li>Validità della promo 3 mesi e solo sul prossimo ordine</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'coupon-conditions-20ANNI_70': {
        title: 'Condizioni di validità Promo 20 Anni',
        body: `
            Condizioni del coupon <b>Promo 20 Anni</b>
            <ul class="list-disc px-6 mt-6">
                <li>Ordine minimo 200&euro; imponibile prodotti</li>
                <li>Validità della promo 3 mesi e solo sul prossimo ordine</li>
                <li>La promo non si applica ai preventivi</li>
            </ul>
        `
    },
    'successfully-edited-profile': {
        title: 'Richiesta modifica anagrafica',
        body: `
            I nostri operatori prenderanno in carico la tua richiesta di modifica anagrafica appena possibile.
        `
    },
    'successfully-edited-profile-filiale': {
        title: 'Anagrafica filiale modificata',
        body: `
            Il profilo della filiale selezionata è stato modificato con successo.
        `
    },
    'confirm-edit-profile': {
        title: 'Modifica Profilo',
        body: function (modalData) {
            return `Confermi le modifiche apportate al tuo profilo?`;
        },
        buttons: [
            {label: 'S&igrave;', data: true, full: true},
            {label: 'No', data: false, full: false},
        ]
    },
    'confirm-edit-profile-filiale': {
        title: 'Modifica Profilo Filiale',
        body: function (modalData) {
            return `Confermi le modifiche apportate alla filiale?`;
        },
        buttons: [
            {label: 'S&igrave;', data: true, full: true},
            {label: 'No', data: false, full: false},
        ]
    },
    'quote-expired': {
        title: 'Preventivo scaduto',
        body: function (modalData) {
            return `
                Il preventivo selezionato è scaduto, richiedine subito uno nuovo a info@ufficiodiscount.it
                <br /><br />
            `;
        },
        buttons: [
            {label: 'Ok', data: true, full: true}
        ]
    },
    'prod-personalizzabili': {
        title: 'Prodotti Personalizzabili',
        body: `
            <p>Stai acquistando un articolo da personalizzare, se non lo hai già fatto, devi prenotare anche l'impianto grafico e inviare il file del tuo marchio in alta risoluzione o in vettoriale al seguente indirizzo: <a href="mailto:info@ufficiodiscount.it">info@ufficiodiscount.it</a></p>
            <br>
            <p>I tempi di consegna di questo articolo saranno 14 gg lavorativi <b>dopo l’approvazione della bozza grafica da parte del cliente</b></p>
        `,
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Ho capito, aggiungi al carrello', data: true, full: true},
        ]
    },
    'prod-personalizzabili-custom': {
        title: 'Prodotti Personalizzabili',
        body: `
            <p>Stai acquistando un articolo da personalizzare</p>
            <br>
            <p>I tempi di consegna di questo articolo saranno 14 gg lavorativi <b>dopo l’approvazione della bozza grafica da parte del cliente</b></p>
        `,
        buttons: [
            {label: 'Annulla', data: false, full: false},
            {label: 'Ho capito, aggiungi al carrello', data: true, full: true},
        ]
    },
    // 'checkout-create-new-customer-confirm': {
    //     title: 'Crea nuova anagrafica',
    //     body: `
    //         Il codice fiscale inserito è già stato registrato.<br />
    //         Creando una nuova anagrafica potete concludere velocemente l'ordine,
    //         ma non sarete in grado di recuperare la lista ordini della precedente anagrafica.<br />
    //         <br /><br />
    //         Confermate la creazione di una nuova anagrafica?
    //     `,
    //     buttons: [
    //         {label: 'Annulla', data: false, full: false},
    //         {label: 'Crea nuova anagrafica', data: true, full: true},
    //     ]
    // },
};
window.modal.productNotAvailable = function (userEmail) {
    return {
        ...window.modal.single('product-not-available'),

        productCode: null,
        emailAddress: userEmail,
        init($refs) {
            $refs.inputEmail.readOnly = !!userEmail;
        },
        sendRequest($dispatch) {
            if (!this.opened)
                return;

            if (!utils.isEmailValid(this.emailAddress)) {
                alert("Email non valida");
                return;
            }

            const productCode = this.modalData.productCode;
            fetch(`/ajaxContents?type=subscript_product_alert&codice=${productCode}&email=${this.emailAddress}`)
                .then(async res => {
                    if (!res.ok)
                        throw new Error(await res.text());
                    return res;
                })
                .then(() => this.close($dispatch))
                .catch(utils.errorLog);
        },
        animation: this.animation
    };
}
window.modal.passwordRecovery = function () {
    return {
        ...window.modal.single('password-recovery'),

        emailAddress: '',
        message: '',

        emailFailed: false,
        telephone: '',

        sendRequest($dispatch) {
            if (!this.opened)
                return;

            if (!utils.isEmailValid(this.emailAddress)) {
                alert("Email non valida");
                return;
            }

            fetchForm(`/sendPassword`, {email: this.emailAddress, from: document.location.href})
                .then(async res => {
                    if (!res.ok)
                        throw new Error(await res.text());
                    return res.json();
                })
                .then((res) => {
                    if (res.response === 'OK') {
                        this.emailFailed = false;
                        this.message = "PASSWORD INVIATA.<br />Riceverete tra breve un e-mail con la Vostra password.";
                    } else {
                        this.emailFailed = true;
                        this.message = "";
                    }
                })
                .catch(reason => alert(reason.message));
        },

        sendRequestTelephone($dispatch) {
            if (!this.opened)
                return;

            fetchForm(`/sendPassword`, {
                email: this.emailAddress,
                telephone: this.telephone,
                from: document.location.href
            })
                .then(async res => {
                    if (!res.ok)
                        throw new Error(await res.text());
                    return res.json();
                })
                .then((res) => {
                    this.message = "Richiesta di registrazione inserita. Vi contatteremo al più presto";
                })
                .catch(reason => alert(reason.message));
        }
    };
}

window.modal.modalArla = function () {
    return {
        ...window.modal.single('payment-selected-confirmation'),
        paymentName: "RIBA 30 giorni Fine Mese",
        paymentSelected: "",
        emailAddress: '',
        message: '',
        secondPayment: "<div></div>",
        emailFailed: false,
        telephone: '',
        init() {
            this.secondPayment = this.modalData.secondPayment;
        },
        open(modalData) {
            this.opened = true;
            this.modalData = {
                ...modalData
            };
            this.init();
            document.body.style.overflow = 'hidden';
        },
        close($dispatch) {
            if (!this.opened)
                return;
            document.body.style.overflow = 'auto';
            $dispatch('single-modal-click', {name});
            this.opened = false;
        },
        sendRequest($dispatch) {
            if (!this.opened)
                return;
        },
    };
}


window.modal.shippingCarrierPreference = function () {
    return {
        ...window.modal.single('shipping-carrier-preference'),
        init($refs) {
        },
        selectedCarrier: '',
        alert: false,
        confirmCarrierPreference($dispatch) {
            if (!this.selectedCarrier) {
                this.alert = true;
                return;
            }
            this.alert = false;

            if (!this.opened)
                return;

            if (document.getElementById('shipping-carrier-preference')) {
                document.getElementById('shipping-carrier-preference').value = this.selectedCarrier;
            }

            this.close($dispatch);
        },
        animation: this.animation
    };
}
